import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption"

import One from "../../../content/assets/wagon-hardware/1.jpg"
import Two from "../../../content/assets/wagon-hardware/2.jpg"
import Three from "../../../content/assets/wagon-hardware/3.jpg"
import Four from "../../../content/assets/wagon-hardware/4.jpg"
import Five from "../../../content/assets/wagon-hardware/5.jpg"

const WagonHardware = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photos = [
    {
      src: One,
      width: 2,
      height: 1,
      caption: `Strap Hinges for a wagon box door. Straps are 1/4 inch by 2 inch by 22 inches. There is a 2" offset. Holes are square to fit 1/4 inch carriage bolts. These are painted semi flat black as the wagon will be outdoors much of the time.`,
    },
    {
      src: Two,
      width: 1.9,
      height: 1,
      caption: `This is a matching pair of hasps one for each side of a wagon box door. The hasp is made of 1/4 inch by 1 1/2 inch stock. The straps are 10 inches from the center of the hinge pin to the end. As the wagon will be outdoors much of the time these were painted semi flat black.`,
    },
    {
      src: Three,
      width: 2,
      height: 1,
      caption: `Left and Right, 3" high, Hasps for Rear Wagon Box Gate.`,
    },
    {
      src: Four,
      width: 1.2,
      height: 1,
      caption: `Left and Right, 3" high, Hasps for Rear Wagon Box Gate.`,
    },
    {
      src: Five,
      width: 2,
      height: 1,
      caption: `Replica (bottom) of an old (top) Decorative Sleigh Top Railing`,
    }
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Wagon & Sleigh Hardware</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default WagonHardware
